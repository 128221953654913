import classNames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';
import styles from './List.module.scss';

type Props = {
  children: ReactNode;
};

export const ListItem = ({ children }: Props): JSX.Element => <div className={styles.item}>{children}</div>;

export const ListItemLarge = ({ children }: Props): JSX.Element => (
  <div className={styles.itemLarge}>{children}</div>
);

export const ListItemHalf = ({ children }: Props): JSX.Element => (
  <div className={styles.itemHalf}>{children}</div>
);

export const ListItemWithStyles = ({
  children,
  className,
}: Props & HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <div className={classNames(styles.itemWithStyles, className)}>{children}</div>
);

export const List = ({ children }: Props): JSX.Element => {
  return (
    <div className={styles.list}>
      <div className={styles.track}>
        <div className={styles.row}>{children}</div>
      </div>
    </div>
  );
};
