import BookItem, { BookItemLargeProps } from 'components/book/ItemLarge';
import BookItemOptions from 'containers/BookItemOptions/BookItemOptions';
import { useMomentCountLoader } from 'dataloaders/momentCountLoader';
import { useReviewLoader } from 'dataloaders/reviewLoader';
import { useMyReadingStates } from 'hooks/useReadingStates';
import { useState } from 'react';
import { ActionItemType } from 'ui/specific/actionList';

type Props = Omit<BookItemLargeProps, 'controls' | 'readingStates' | 'momentsCount'> & {
  profileId?: string;
  additionalOptions?: ActionItemType[];
  optionsPositioningStrategy?: 'fixed' | 'absolute';

  // Controls the display of the network avatars on the book item
  hideAvatar?: boolean;
};

const BookItemsLargeContainer = ({
  profileId,
  additionalOptions,
  optionsPositioningStrategy = 'absolute',
  hideAvatar = false,
  ...props
}: Props): JSX.Element => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const { data: momentsCount } = useMomentCountLoader({ bookId: props.book.id, profileId });
  const { data: review } = useReviewLoader({ profileId, bookId: props.book.id });
  const { readingStates } = useMyReadingStates();

  return (
    <BookItem
      {...props}
      onLongPress={() => setOpenDropdown(true)}
      momentsCount={momentsCount}
      readingStates={readingStates || []}
      hideAvatar={hideAvatar}
      review={review}
      controls={
        <BookItemOptions
          book={props.book}
          additionalOptions={additionalOptions}
          positioningStrategy={optionsPositioningStrategy}
          isOpen={isOpenDropdown}
          setOpen={setOpenDropdown}
        />
      }
    />
  );
};

export default BookItemsLargeContainer;
