import { ClubPartsFragment, ClubStatsFragment, ProfilePartsFragment } from 'generated/graphql';
import { ReactNode } from 'react';
import { ClubAvatar } from 'ui/specific/club/ClubAvatar';
import styles from './ClubInline.module.scss';
import { Inline } from './Inline';

type ClubBase = Pick<ClubPartsFragment, 'id' | 'name' | 'handle' | 'image' | 'description'> & {
  memberships: { profile: ProfilePartsFragment }[];
};

type BaseProps = {
  right?: string | React.ReactNode;
  border?: boolean;
  link?: boolean;
};

type ClubWithHandleProps = BaseProps & {
  bottomContent?: 'handle'; // handle - shows the club handle as preview
  club: ClubBase;
};

type ClubWithStatsProps = BaseProps & {
  bottomContent?: 'stats'; // stats - shows the club stats (members and books) as preview
  club: ClubBase & ClubStatsFragment;
};

type ClubWithExcerptProps = BaseProps & {
  bottomContent?: 'excerpt'; // excerpt - shows an excerpt of the description
  club: ClubBase & ClubStatsFragment;
};

type Props = ClubWithStatsProps | ClubWithHandleProps | ClubWithExcerptProps;

export const ClubInline = ({ club, bottomContent = 'handle', link = false, ...rest }: Props): JSX.Element => {
  let bottom: ReactNode = '';
  if (bottomContent === 'handle') bottom = `@${club.handle}`;
  if (bottomContent === 'stats') bottom = <ClubStats club={club} />;
  if (bottomContent === 'excerpt') bottom = club.description;

  return (
    <Inline
      left={<ClubAvatar club={club} size="small" />}
      top={club.name}
      bottom={bottom}
      href={link ? '/club/[clubHandle]' : undefined}
      as={link ? `/club/${club.handle}` : undefined}
      {...rest}
    />
  );
};

const ClubStats = ({ club }: { club: ClubStatsFragment & ClubBase }) => {
  if (!club.bookCount && !club.membershipCount) return null;

  const bookCount = `${club.bookCount} book${club.bookCount === 1 ? '' : 's'}`;
  const memberCount = `${club.membershipCount} member${club.membershipCount === 1 ? '' : 's'}`;
  return (
    <div className={styles.dotRow}>
      {club.bookCount !== undefined && bookCount} • {club.membershipCount !== undefined && memberCount}
    </div>
  );
};
