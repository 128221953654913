import classnames from 'classnames';
import { ClubPartsFragment } from 'generated/graphql';
import Link from 'next/link';
import styles from './ClubAvatar.module.scss';

type AvatarSize = 'small' | 'medium' | 'fill';

type Props = {
  club: Pick<ClubPartsFragment, 'id' | 'name' | 'image'>;
  size?: AvatarSize;
  disableLazyLoading?: boolean;
};

export const ClubAvatar = ({ club, size = 'medium', disableLazyLoading }: Props) => {
  const initials = club.name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .slice(0, 4)
    .join('')
    .replace(/[\W_]+/g, '');

  if (club.image) {
    return (
      <img
        className={classnames(styles.image, styles[size])}
        src={`${club.image}?size=${getImageSize(size)}`}
        alt={initials}
        loading={disableLazyLoading ? 'eager' : 'lazy'}
      />
    );
  } else {
    return <div className={classnames(styles.fallback, styles[size])}>{initials}</div>;
  }
};

type LinkProps = {
  club: ClubPartsFragment;
};

export const ClubAvatarLink = ({ club }: LinkProps) => {
  return (
    <Link href="/club/[clubHandle]" as={`/club/${club.handle}`}>
      <a className={styles.container}>
        <ClubAvatar club={club} />
        <div className={styles.name}>{club.name}</div>
      </a>
    </Link>
  );
};

const getImageSize = (size: AvatarSize): number => {
  if (size === 'fill') return 1200;
  return 200;
};
