import classNames from 'classnames';
import { BookPartsFragment, ReadingStateWorkPartsFragment, ReviewPartsFragment } from 'generated/graphql';
import { useLongPress } from 'hooks/useLongPress';
import Link from 'next/link';
import { ReactNode, useMemo } from 'react';
import { Cover, Tag } from 'ui/generic';
import { LibraryIcon } from 'ui/icons';
import { noop } from 'utils/noop';
import InlineBookStatus from './InlineBookStatus';
import styles from './ItemLarge.module.scss';

type InnerProps = {
  book: BookPartsFragment;
  readingStates?: ReadingStateWorkPartsFragment[];
  momentsCount?: number;
  hideAvatar?: boolean;
  subtitle?: string | ReactNode;
  review?: ReviewPartsFragment | null;
};

const Inner = ({ book, readingStates, momentsCount, hideAvatar = false, subtitle, review }: InnerProps) => {
  const isShowingAvatar = useMemo(
    () => readingStates && readingStates?.some((x) => x.bookId == book.id),
    [readingStates, book]
  );

  return (
    <div className={styles.inner}>
      <div className={styles.coverOuter}>
        <div className={styles.cover}>
          <Cover book={book} assetSize="600" />
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.stickers}>
          {!hideAvatar && isShowingAvatar && (
            <div className={styles.yourStatus}>
              <Tag icon={<LibraryIcon />}></Tag>
            </div>
          )}

          <InlineBookStatus momentsCount={momentsCount} rating={review?.rating} />
        </div>
        <h3 className={classNames('sectionHeader', styles.title)}>{book.title}</h3>
        <h4 className={styles.subtitle}>{book.subtitle}</h4>
        <span className={styles.author}>{book.authors.map((a) => a.name).join(', ')}</span>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
    </div>
  );
};

export type BookItemLargeProps = {
  onClick?: () => void;
  href?: string;
  as?: string;
  controls?: ReactNode;
  hideAvatar?: boolean;
  subtitle?: string | ReactNode;
  onLongPress?: () => void;
} & InnerProps;

const ItemLarge = ({
  book,
  href,
  as,
  onClick,
  controls = null,
  readingStates,
  momentsCount,
  hideAvatar = false,
  subtitle,
  review,
  onLongPress = noop,
}: BookItemLargeProps): JSX.Element => {
  const longPress = useLongPress(onLongPress);

  if (onClick) {
    return (
      <div className={styles.item}>
        <button onClick={onClick} className={styles.outerButton} {...longPress()}>
          <Inner
            book={book}
            readingStates={readingStates}
            momentsCount={momentsCount}
            hideAvatar={hideAvatar}
            subtitle={subtitle}
            review={review}
          />
        </button>
        {controls && <div className={styles.controls}>{controls}</div>}
      </div>
    );
  }
  return (
    <div className={styles.item} {...longPress()}>
      <Link href={href || '/book/[bookSlug]'} as={as || `/book/${book.slug}`}>
        <a draggable="false">
          <Inner
            book={book}
            readingStates={readingStates}
            momentsCount={momentsCount}
            hideAvatar={hideAvatar}
            subtitle={subtitle}
            review={review}
          />
        </a>
      </Link>
      {controls && <div className={styles.controls}>{controls}</div>}
    </div>
  );
};

export default ItemLarge;
