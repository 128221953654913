import { BookPartsFragment, ShelfPartsFragment } from 'generated/graphql';
import { useScreenSize } from 'hooks/useScreenSize';
import throttle from 'lodash.throttle';
import Link from 'next/link';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Cover } from 'ui/generic';
import { DotIcon } from 'ui/icons';
import styles from './Item.module.scss';

type Props = {
  shelf: ShelfPartsFragment & {
    books: Array<BookPartsFragment>;
  };
  ownerHandle: string;
  booksCount?: number;
  showOwner?: boolean;
};

const Item = ({ shelf, ownerHandle, showOwner, booksCount }: Props): JSX.Element => {
  const [descriptionLines, setDescriptionLines] = useState<number>(0);
  const top3Books = shelf.books.slice(0, 3);
  const container = useRef<HTMLAnchorElement>(null);
  const header = useRef<HTMLDivElement>(null);

  const { smScreen, mdScreen } = useScreenSize();

  const updateLines = useCallback(
    throttle(function () {
      if (container.current && header.current) {
        const containerHeight = container.current?.scrollHeight;
        const headerHeight = header.current?.scrollHeight;
        setDescriptionLines(Math.floor((containerHeight - headerHeight - 64 - 20.3 - 20) / 20.3));
      }
    }, 200),
    [setDescriptionLines]
  );

  useEffect(() => {
    updateLines();
    window.addEventListener('resize', updateLines);
    return () => window.removeEventListener('resize', updateLines);
  }, [container.current, header.current, smScreen, mdScreen]);

  return (
    <Link href="/[handle]/shelf/[shelfSlug]" as={`/${ownerHandle}/shelf/${shelf.slug}`}>
      <a className={styles.container} ref={container}>
        <div className={styles.info}>
          <div className={styles.title} ref={header}>
            <h3 className="sectionHeader">{shelf.title}</h3>
          </div>
          <div className={styles.details}>
            {showOwner && (
              <span className={styles.user}>
                @{ownerHandle}
                <span className={styles.dot}>
                  <DotIcon />
                </span>
              </span>
            )}
            {booksCount !== undefined && (
              <span className={styles.booksCount}>
                {booksCount} book{booksCount === 1 ? '' : 's'}
              </span>
            )}
          </div>
          {shelf.description && descriptionLines > 0 && (
            <span
              className={styles.description}
              style={{
                ['--lines' as never]: descriptionLines,
              }}
            >
              <p>{shelf.description}</p>
            </span>
          )}
        </div>
        <div className={styles.images}>
          {top3Books.map((book) => (
            <div className={styles.image} key={book.id}>
              <Cover book={book} />
            </div>
          ))}
        </div>
      </a>
    </Link>
  );
};

export default Item;
